import React from 'react';
import Box from '@material-ui/core/Box';
import { styled } from '@material-ui/core/styles';

const LoaderComponent = (): JSX.Element => {
  return (
    <div className='loadingio-spinner-spin-m6b1jlqr7s'>
      <div className='ldio-kes2zmp9rin'>
        <div>
          <div />
        </div>
        <div>
          <div />
        </div>
        <div>
          <div />
        </div>
        <div>
          <div />
        </div>
        <div>
          <div />
        </div>
        <div>
          <div />
        </div>
        <div>
          <div />
        </div>
        <div>
          <div />
        </div>
      </div>
    </div>
  );
};

export default LoaderComponent;

export const LoaderWrapper = styled(Box)({
  width: '100%',
  height: '100%',
  position: 'absolute',
  zIndex: 99,
  top: 0,
  display: 'flex',
  alignItems: 'center',
  background: '#ffffff73',
});

export const FixedLoaderWrapper = styled(Box)({
  width: '100%',
  height: '100%',
  position: 'fixed',
  zIndex: 99,
  top: 0,
  display: 'flex',
  alignItems: 'center',
  background: '#ffffff73',
});
