import { createSlice } from '@reduxjs/toolkit';
import { intialStateType } from './agencyImaging.interface';
import { handleLoaders, getAgencyImaging } from './agencyImaging.caseReducers';

const agencyImagingSlice = createSlice({
  name: 'RF_agencyImaging',
  initialState: {
    agencyImaging: {
      data: null,
      error: '',
      loading: false,
    },
  } as intialStateType,
  reducers: {
    handleLoaders,
    getAgencyImaging,
  },
});

export { agencyImagingSlice };
export default agencyImagingSlice.reducer;
