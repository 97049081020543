import React from 'react';
import { Provider } from 'react-redux';
import AppRouter from 'utils/Routes';
import { store } from 'utils/store';
import './resources/styles/styles.scss';
import { ToastContainer } from 'react-toastify';


const App: React.FC = () => {
  return (
    <Provider store={store}>
      <ToastContainer
        position='top-right'
        autoClose={6000}
        newestOnTop={true}
        hideProgressBar
        closeOnClick={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
      />
      <AppRouter />
    </Provider>
  );
};

export default App;
