import CallApi from 'utils/HttpClient';
import APIUrlconstants from "utils/apiEndpoints";
import { TriggerToaster } from 'utils/helper';
import * as reducerActions from "core/reducers/auth/auth.actions";
import * as masterActions from 'core/reducers/master/master.actions';

const { API_URLS } = APIUrlconstants;
(window as any).refreshTokenInterval = null;
(window as any).refreshTokenTimeout = null;

export const b2eSignIn = ():any => {
    return async (dispatch: CallableFunction): Promise<void> => {
        try {
            dispatch(updateAuthenticating(true));
            sessionStorage.removeItem('prevLogin')
            window.location.href = API_URLS.b2eSignIn;
        } catch (err) {
            console.log('on Error', err);
        }
    }
};

export const updateAuthenticating = (status:boolean):any => 
(dispatch: CallableFunction): void => dispatch(reducerActions.setAuthenticating(status));

export const doSignout = () => {
    localStorage.setItem('logoutClearance', 'required')
    window.location.href = API_URLS.doSignout;
};


export const refreshTokenAPi = () => CallApi.get(`${API_URLS.doTokenRefresh}`);

export const getAuthUserApi = ():any => new Promise((resolve, reject) => {
    CallApi.get(`${API_URLS.getAuthUser}`)
    .then((result) => resolve(result))
    .catch(async (err) => {
        console.log('error on user API', err);
        try {
            await refreshTokenAPi();
            const result = await CallApi.get(`${API_URLS.getAuthUser}`)
            resolve(result);
        } catch(err) {
            reject(err)
        }
    })
})

export function getAgentCodeJsonApi (userDetails:any):any {
    return async (dispatch: CallableFunction): Promise<void> => {
        dispatch(
        masterActions.handleLoaders({
              loaderApplying: 'agentCodes',
              loading: true,
            })
          );
        try {
           const { data: { Items } } = await CallApi.get(`${API_URLS.getAgentCodeJson}`);
           const { AgentNetUserFirmList, StaffNetUsers } = Items[0];
           const parsedAgentCodes = JSON.parse(AgentNetUserFirmList)['AgentNetUserFirmList'];
           const parsedStaffNetUsers = JSON.parse(StaffNetUsers)['StaffNetUsers'];
           const userData = userDetails.data;
           const dispatchData = {
            userData, 
            parsedStaffNetUsers,
           };
           dispatch(reducerActions.updateUsersAccessTabs(dispatchData));
           dispatch(masterActions.getAgentCodes({
               data: parsedAgentCodes,
           }));
        } catch (err:any) {
            dispatch(
            masterActions.handleLoaders({
                loaderApplying: 'agentCodes',
                loading: false,
              })
            );
            TriggerToaster('error', 'Unable to get staffnet users and agent codes')
        }

        // dispatch(reducerActions.storeAuthUser({ data: userDetails }))
    }
}



export const handleTokenRefreshFailure = () => {
    TriggerToaster('warning', 'Token refresh got failed, Please login again')
    setTimeout(() => {
        sessionStorage.clear();
        const orginUrl = window.location.origin;
        const targetUrl = `${orginUrl}/login`
        window.location.href = targetUrl
    }, 6000)
}

export const handleTokenExpiry = (message?: string) => {
    TriggerToaster('warning', message || 'Token expired, Please login again')
    sessionStorage.clear();
    setTimeout(() => {
        sessionStorage.clear();
        sessionStorage.setItem('prevLogin', 'true')
        doSignout()
    }, 6000)
}

export const initializeTokenRefresh = ({ iat, exp }: { iat: number, exp: number }) => {
    const tokenExpDuration = exp - iat; // in seconds
    const tokenExpDurationMs = (tokenExpDuration * 1000) - (300 * 1000); // reducing by 5 mins
    clearInterval((window as any).refreshTokenInterval);
    (window as any).refreshTokenInterval = setInterval(async () => {
        try {
            await refreshTokenAPi();
            const { data } = await getAuthUserApi();
            const { data: { iat, exp } } = data;
            initializeTokenRefresh({ iat, exp }); // calling recursivelly
        } catch (err) {
            handleTokenRefreshFailure()
        }
    }, tokenExpDurationMs)
}

export const handleTokenExpiration = async ({ iat, exp }: { iat: number, exp: number }) => {
    const expInMilliseconds = exp * 1000; // expiry time in milliseconds
    const tokenExpireTime = expInMilliseconds - (300 * 1000); // reducing expiry time by 5 mins
    const currentTime = Date.now();
    const intialTimeout = tokenExpireTime - currentTime ;
    console.log("logging >> on handleTokenExpiration", intialTimeout)
    clearTimeout((window as any).refreshTokenTimeout); // clearing timeout
    if (intialTimeout > 0) { // time out is not over
        (window as any).refreshTokenTimeout = setTimeout(async () => {
            try {
                await refreshTokenAPi();
                const { data } = await getAuthUserApi();
                const { data: { iat, exp } } = data;
                handleTokenExpiration({ iat, exp })
            } catch (err) {
                console.log("checking token refresh failure", err)
                handleTokenRefreshFailure();
            }
        }, intialTimeout)
    } else {
        try {
            await refreshTokenAPi();
            const { data } = await getAuthUserApi();
            const { data: { iat, exp } } = data;
            handleTokenExpiration({ iat, exp })
        } catch (err) {
            console.log("checking token expiry error", err)
            handleTokenExpiry()
        }
    }

}

export function dispatchUserDetails(userDetails: any):any {
    return async (dispatch: CallableFunction): Promise<void> => {
        dispatch(reducerActions.storeAuthUser({ data: userDetails }))
    }
}

export function clearUserDetails() {
    return (dispatch: CallableFunction): void => {
        dispatch(reducerActions.storeAuthUser({ data: null }))
    }
}

