import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import overrides from './overrides';
import palette from './palette';
import typography from './typography';


const themeData:any = {
  palette,
  typography,
  overrides,
}

const defaultTheme = createMuiTheme(themeData);

const ThemeWrapper: React.FC = ({ children }) => (
  <MuiThemeProvider theme={defaultTheme}>{children}</MuiThemeProvider>
);

export default ThemeWrapper;
