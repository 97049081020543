import { dispatchUserDetails, handleTokenExpiration } from "core/apiActions/auth";
import { RootState } from "core/reducers/index";
import { useSelector } from "react-redux";
import { processAuthResponse } from "utils/helper";
import { useAppDispatch } from "utils/store";

export const useHandleAuthResponse = (): any[] => {
    const dispatch = useAppDispatch();
    const userDetails = useSelector((state: RootState) => state.Auth_Data.userDetails.data);
    const isAuthenticated = useSelector((state: RootState) => state.Auth_Data.isAuthenticated);
    const doDispatchUserDetails = ({ data }: any) => {
        const { userDetails, iat, exp } = processAuthResponse(data);
        if (userDetails) {
            dispatch(dispatchUserDetails(userDetails))
        }
        if (iat && exp) handleTokenExpiration({ iat, exp });
    }
    return [isAuthenticated, userDetails, doDispatchUserDetails]
}