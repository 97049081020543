import Loadable from 'react-loadable';
import Loading from '../../modules/Components/componentLoader';


const AgencyImaging = Loadable({
  loading: Loading,
  loader: () => import('../../modules/Containers/AgencyImaging'),
});

const Staffnet = Loadable({
  loading: Loading,
  loader: () => import('../../modules/Containers/Staffnet'),
});

const DocumentView = Loadable({
  loading: Loading,
  loader: () => import('../../modules/Components/DocumentView'),
});

export const PrintView = Loadable({
  loading: Loading,
  loader: () => import('../../modules/Containers/Common/PrintView'),
});

export const SSOLandingPage = Loadable({
  loading: Loading,
  loader: () => import('../../modules/Containers/Common/SingleSingOn'),
});

export const UnauthorizedUser = Loadable({
  loading: Loading,
  loader: () => import('../../modules/Containers/Common/Unauthorized'),
});


const Routes = [
  {
    component: AgencyImaging,
    exact: true,
    path: '/agency-imaging',
    name: 'Agency Images',
    permission: { isAuth: true },
    layoutSettings: {
      title: 'SearchFile',
      topbar: false,
    },
  },
  {
    component: DocumentView,
    exact: true,
    path: '/agency-imaging/documentView',
    name: 'Agency Images',
    permission: { isAuth: true },
    layoutSettings: {
      title: 'SearchFile',
      topbar: false,
    },
  },
  {
    component: Staffnet,
    exact: true,
    path: '/staffnet',
    name: 'StaffNet',
    permission: { isAuth: true },
    layoutSettings: {
      title: 'SearchFile',
      topbar: false,
    },
  },

  {
    component: DocumentView,
    exact: true,
    path: '/staffnet/documentView',
    name: 'StaffNet',
    permission: { isAuth: true },
    layoutSettings: {
      title: 'SearchFile',
      topbar: false,
    },
  },
];

export default Routes;
