import React, { useMemo, useCallback, useState } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { useHandleAuthResponse } from "hooks/authHandler";
import LoaderComponent from "modules/Components/componentLoader";
import { useSelector } from "react-redux";
import { RootState } from "core/reducers/index";
import {
  getAuthUserApi,
  getAgentCodeJsonApi,
  updateAuthenticating,
} from "core/apiActions/auth";
import { useAppDispatch } from "utils/store";
import LayoutWrapper from "modules/Components/layoutWrapper";
import ThemeWrapper from "../../Theme";

const RouteAuthentication = (routeProps: any) => {
  const {
    component: Component,
    permission,
    layoutSettings,
    ...rest
  } = routeProps;

  const dispatch = useAppDispatch();
  const history = useHistory();
  const isAuthenticating = useSelector(
    (state: RootState) => state.Auth_Data.isAuthenticating
  );

  const userTabs = useSelector((state: RootState) => state.Auth_Data.userAccessTabs);

  const [isAuthenticated, userDetails, doDispatchUserDetails] =
    useHandleAuthResponse();

  const handleGetAuthUser = useCallback(async () => {
    try {
      dispatch(updateAuthenticating(true));
      const result = await getAuthUserApi();
      await dispatch(getAgentCodeJsonApi(result));
      doDispatchUserDetails(result);
    } catch (err) {
      dispatch(updateAuthenticating(false));
      // Redirecting to landing page
      history.push('/')
    }
  }, [dispatch, doDispatchUserDetails]);

  const isUserAuthenticated = useMemo<boolean>(() => {
    if (isAuthenticated && !userTabs?.includes(routeProps.name)) {
      history.push('/unauthorized')
      return false
    } else if(isAuthenticated) {
      return true
    } else if (!isAuthenticating) {
      // user not authenticating and authenticated
      const computedUrl = routeProps?.computedMatch?.url || "";
      localStorage.setItem("preRoute", computedUrl);
      handleGetAuthUser();
      return false;
    } else {
      return false
    }
  }, [handleGetAuthUser, isAuthenticated, isAuthenticating, routeProps, userTabs]);

  return (
    <Route
      {...rest}
      render={(props: any) => {
        if ( isAuthenticating || !isUserAuthenticated ) {
          return <LoaderComponent />;
        } else if (isUserAuthenticated) {
          if (permission?.isAuth) {
            return (
              <ThemeWrapper>
                <LayoutWrapper layoutSettings={layoutSettings}>
                  <Component {...{ props, layoutSettings }} />
                </LayoutWrapper>
              </ThemeWrapper>
            );
          } else if (!permission?.isAuth) {
            return <Redirect to="/unauthorized" />;
          }
        } else {
          return <Redirect to="/" />;
        }
      }}
    />
  );
};

export default RouteAuthentication;
