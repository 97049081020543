/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable @typescript-eslint/no-use-before-define */
import axios, { Method } from 'axios';
import { requestHandler, responseHandler, handleError } from './helper';

axios.defaults.withCredentials = true;
axios.interceptors.request.use(requestHandler);
axios.interceptors.response.use(responseHandler);

const doGet = (url: string, params?: any): Promise<any> => {
  const response = new Promise(function (resolve, reject) {
    return axios({
      method: 'get',
      url: `${url}`,
      params,
    })
      .then((ApiResponse: any) => {
        resolve(ApiResponse);
      })
      .catch((ApiError: any) => {
        reject(handleError(ApiError));
      });
  });
  return response;
};

const doPost = (url: string, data: any): any => {
  return doCommonAxiosCall(url, 'POST', data);
};

const doPut = (url: string, data?: any): any => {
  return doCommonAxiosCall(url, 'PUT', data);
};

const doDelete = (url: string, data?: any): any => {
  return doCommonAxiosCall(url, 'DELETE', data);
};

const doCommonAxiosCall = (url: string, method: Method, data?: any): any => {
  const response = new Promise(function (resolve, reject) {
    return doAxiosCall(url, method, resolve, reject, data);
  });
  return response;
};

const doAxiosCall = (
  url: string,
  method: Method,
  resolve: any,
  reject: any,
  data?: any
) => {
  return axios({
    method,
    url: `${url}`,
    data,
  })
    .then((response: any) => {
      resolve(response);
    })
    .catch((response: any) => {
      reject(handleError(response));
    });
};

export default {
  get: doGet,
  post: doPost,
  put: doPut,
  delete: doDelete,
};
