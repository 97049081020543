import MyLog from 'resources/images/icons/myLogIcon.svg';
import StaffNet from 'resources/images/icons/staffNet.svg';
import AgencyImaging from 'resources/images/icons/agency.svg';

const navList = [
  {
    navLabel: 'Agency Imaging',
    route: '/agency-imaging',
    nestedItems: [],
    icon: AgencyImaging,
    onRoutes: ['/agency-imaging', '/agency-imaging/documentView'],
    accessName: 'Agency Images',
  },
  {
    navLabel: 'StaffNet',
    route: '/staffnet',
    nestedItems: [],
    onRoutes: ['/staffnet'],
    accessName: 'StaffNet',
    icon: StaffNet,
  },
];

export default navList;
