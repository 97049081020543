import { createSlice } from '@reduxjs/toolkit';
import {
  getScanImages,
  handleLoaders,
  getNextScanImages,
  storeUserDetails,
  updateSidebar,
} from './general.casereducer';
import { initialStateType } from './general.interface';

const generalReducerSlice = createSlice({
  name: 'General_Data',
  initialState: {
    imagesOnThumnail: {
      data: [],
      loading: false,
      error: '',
    },
    imagesOnThumnailNext: {
      data: [],
      loading: false,
      error: '',
    },
    userDetails: {
      data: null,
      loading: false,
    },
    convertingPdf: {
      data: null,
      loading: false,
    },
    gettingDirectTiff: {
      data: null,
      loading: false,
    },
    sidebar: {
      open: true,
    },
  } as initialStateType,
  reducers: {
    getScanImages,
    getNextScanImages,
    handleLoaders,
    storeUserDetails,
    updateSidebar,
  },
});

export { generalReducerSlice };

export default generalReducerSlice.reducer;
