import React, { useMemo } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { useHistory, useRouteMatch } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import NavBarList from "../../../utils/navBarList";
import Tooltip from "@material-ui/core/Tooltip";
import { useSelector } from "react-redux";
import { RootState } from "core/reducers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      maxWidth: 240,
      height: "90vh",
      overflow: "auto",
      zIndex: 1,

      minWidth: "220px",
      position: "fixed",
      [theme.breakpoints.down("lg")]: {
        maxWidth: 230,
      },
      "&::-webkit-scrollbar": {
        width: "15px",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "8px",
        border: "4px solid transparent",
        backgroundClip: "content-box",
        backgroundColor: "#366390",
      },
    },
    arrow: {
      color: "#1c1c1c",
    },
    tooltip: {
      backgroundColor: "#1c1c1c",
      color: "white",
    },
    selectedDiv: {
      padding: "2px",
      height: "48px",
      marginLeft: "3px",
      position: "absolute",
      zIndex: 1,
      backgroundColor: "#FFF",
      borderRadius: "6px",
      [theme.breakpoints.down("lg")]: {
        height: "32px",
        marginTop: "2px",
      },
      [theme.breakpoints.up("lg")]: {
        height: "32px",
        marginTop: "2px",
      },
    },
    textNormal: {
      "& .MuiTypography-body1": {
        fontSize: "18px",
        fontWeight: 300,
        [theme.breakpoints.down("lg")]: {
          fontSize: "16px",
        },
      },
    },
    subItemLabel: {
      paddingLeft: "16px",
    },
    selectedClass: {
      ".Mui-selected&, .Mui-selected&:hover": {
        [theme.breakpoints.down("lg")]: {
          fontSize: "16px !important",
        },
        fontSize: "18px",
      },
      ".Mui-selected& .MuiTypography-body1": {
        [theme.breakpoints.down("lg")]: {
          fontSize: "16px !important",
        },
        fontSize: "18px",
        fontWeight: 500,
        color: "white",
      },
    },
    sidebarIcons: {
      margin: "0px 13.9px 0px 0px",
    },
    iconsSidebaView: {
      width: "100%",
      minWidth: "45px",
      height: "100vh",
      overflow: "auto",
      zIndex: 1,
      "&::-webkit-scrollbar": {
        width: "4px",
      },
      "& .MuiListItemText-root": {
        display: "none",
      },
      position: "fixed",
      [theme.breakpoints.down("lg")]: {
        maxWidth: "3%",
      },
      [theme.breakpoints.down("sm")]: {
        maxWidth: "5%",
      },
      [theme.breakpoints.down("xl")]: {
        maxWidth: "1%",
      },
    },
    sidebarMini: {
      Visibility: "hidden",
    },
  })
);

interface NavData {
  navLabel: string;
  route?: string;
  onRoutes?: string[];
  nestedItems?: [] | NavData[];
  icon: string;
  accessName?: string;
}

export default function NestedList({
  navList,
  isOpen,
}: {
  navList?: string;
  isOpen: boolean;
}): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const routeMatch = useRouteMatch();
  const { path: routePath } = routeMatch;
  const userTabs = useSelector((state: RootState) => state.Auth_Data.userAccessTabs);

  const handleRoute = (navData: any) => {
    const url = navData.route;
    if (navData.redirect) {
      const redirectUrl = navData.getRoute(navData.navLabel);
      window.location.href = redirectUrl;
    } else history.push(url);
  };

  const FilteredNavBarList = useMemo(() => {
    return NavBarList.filter((NavListData:NavData) => !!userTabs.includes(NavListData?.accessName || ""))
  },[userTabs])

  return (
    <div>
      <List
        component="nav"
        className={isOpen ? classes.root : classes.iconsSidebaView}
      >
        {FilteredNavBarList.map((navData: NavData) => {
          const listArray = [];
          const selected = navData?.onRoutes?.includes(routePath);
          listArray.push(
            <div key={navData.navLabel}>
              {selected ? <div className={classes.selectedDiv} /> : null}
              <ListItem
                className={classes.selectedClass}
                button
                selected={selected}
                onClick={() => handleRoute(navData)}
              >
                <Tooltip
                  title={navData.navLabel}
                  placement="right"
                  arrow
                  classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
                >
                  <img
                    src={navData.icon}
                    alt=""
                    className={classes.sidebarIcons}
                  />
                </Tooltip>
                <ListItemText
                  className={classes.textNormal}
                  primary={navData.navLabel}
                />
              </ListItem>
            </div>
          );
          return listArray;
        })}
      </List>
    </div>
  );
}
