/* eslint-disable import/no-anonymous-default-export */
export default {
    root: {
        backgroundColor: "#002a46",
        "& .MuiListItem-button.Mui-selected": {
            backgroundColor: "#00436e",
            color: "#f0e7fd",
        },
        "& .MuiListItem-button.Mui-selected:hover": {
            backgroundColor: "#00436e",
            color: "#f0e7fd",
        },
        '& .MuiTypography-body1': {
            color: "#a9cde4",
        },
        '& .MuiListItem-button': {
            height: '40px'
        }
    },
};
