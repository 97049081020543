/* eslint-disable import/no-anonymous-default-export */
export default {
  label: {
    fontFamily: '"Roboto", sans-serif',
    fontWeight: '500',
  },
  root: {
    fontSize: '1rem',
    // width: '100%',
    height: '32px',
    lineHeight: 1.25,
    margin: '4px 6px',
    textTransform: 'none',
    '.MuiButton-contained.Mui-disabled&': {
      opacity: '0.6',
      color: 'rgba(255,255,255)',
      backgroundColor: '#498cb7',
    },
  },
  sizeSmall: {
    padding: '4px 15px',
  },
  '&$disabled': {
    color: 'rgba(255,255,255,0.8)',
    backgroundColor: 'rgb(58, 71, 107)',
    opacity: '0.8',
  },
};
