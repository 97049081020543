/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { intialStateType } from './master.interface';
import {
  getMasterStates,
  getMasterCountries,
  getAgentCodes,
  handleLoaders
} from './master.caseReducer';

const masterDataSlice = createSlice({
  name: 'Master_Data',
  initialState: {
    masterStates: {
      data: [],
      error: '',
      loading: false,
    },
    masterCountries: {
      data: [],
      error: '',
      loading: false,
    },
    masterWarehouses: {
      data: [],
      error: '',
      loading: false,
    },
    agentCodes: {
      data: [],
      error: '',
      loading: false,
    },
  } as intialStateType,
  reducers: {
    getMasterStates,
    getMasterCountries,
    getAgentCodes,
    handleLoaders
  },
});

export { masterDataSlice };

export default masterDataSlice.reducer;
