import moment from 'moment';
import { toast } from 'react-toastify';
import APIUrlconstants, { AUTH_SERVICE_BASE_URL } from 'utils/apiEndpoints';
import { BASE_URL, PRM_REDIRECT_URL } from 'utils/Constants';

const { API_URLS } = APIUrlconstants;

export function TriggerToaster(type: string, message: string, options = {}) {
  return (toast as any)[`${type}`](message, { ...options, toastId: message });
}

export const deepFind = (obj: any, path: any, parent?: any) => {
  const paths = (path || '').split('.');
  let current = parent ? obj[parent?.index][parent?.fieldName] : obj;

  for (let i = 0; i < paths.length; i += 1) {
    if (isExist(current[paths[i]])) {
      current = current[paths[i]];
    } else {
      return '';
    }
  }
  return current;
};

export const isExist = (value: any, defaultValue?: any) => {
  if (Array.isArray(value)) {
    return value.length !== 0;
  }
  const isValExist =
    value === undefined ||
    value === null ||
    value === '' ||
    value === defaultValue;
  if (!isValExist) {
    return !(Object.keys(value).length === 0 && value.constructor === Object);
  }
  return !isValExist;
};

export const isEmptyValue= {
    forObject: (ObjectParam:any) => Object.keys(ObjectParam).every((value:any) => !(ObjectParam[value])),
    forArray: () => {}
  }


export const monthNameDateYearFormat = (dateString: string) =>
  dateString && (dateString.split("T")[0] !== "0001-01-01" && dateString.split("T")[0] !== "1900-01-01")
    ? moment(new Date(dateString)).format('MM/DD/YYYY') : '';

export const monthNameDateYearFormatWithTime = (dateString: string) =>
  dateString && (dateString.split("T")[0] !== "0001-01-01" && dateString.split("T")[0] !== "1900-01-01")
    ? moment(new Date(dateString)).format('MM/DD/YYYY, hh:mm:ss A')
    : '';
export const formatDateToString = (dateString: string) =>
  dateString && (dateString.split("T")[0] !== "0001-01-01" && dateString.split("T")[0] !== "1900-01-01")
    ? moment(new Date(dateString)).format('MM/DD/YYYY hh:mm:ss A')
    : '';
export const getTimeString = (now: any) =>
  now ? moment(new Date(now)).format('hh:mm:ss A') : '';
export const getTrimmedValue = (targetObj: any) => {
  const newObj: any = {};
  if (!targetObj || typeof targetObj !== 'object') return newObj;
  Object.keys(targetObj).forEach((tarObjKey: typeof newObj) => {
    newObj[tarObjKey] =
      typeof targetObj[tarObjKey] === 'string'
        ? targetObj[tarObjKey].trim()
        : targetObj[tarObjKey];
  });
  return newObj;
};

export const getInitialValues = (fields: any, data = {}): any => {
  if (!Array.isArray(fields)) return {};
  //declare an empty initialValues object
  const initialValues: any = {};
  //loop loop over fields array
  //if prop does not exit in the initialValues object,
  // pluck off the name and value props and add it to the initialValues object;
  fields.forEach((field) => {
    if (!initialValues[field.name]) {
      initialValues[field.name] = field.defaultValue || '';
    }
  });
  return { ...initialValues, ...data };
};

function uuidv4() {
  // function to generate GUID / UUID
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : r && 0x3 | 0x8;
    return v.toString(16);
  });
}

export const resetValues = (fields: any[], data: any = {}) => {
  const initialValues: any = {};
  fields.forEach((field: any) => {
    if (!initialValues[field.name]) {
      initialValues[field.name] = field.isPersist
        ? data[field.name]
        : field.defaultValue || '';
    }
  });
  return { ...initialValues };
};


export const safelyParseJSON = (json: any) => {
  // This function cannot be optimised, it's best to
  // keep it small!
  var parsed;
  try {
    parsed = JSON.parse(json);
  } catch (e) {
    // Oh well, but whatever...
  }
  return parsed; // Could be undefined!
};

export const triggerWindowScroll = (xAxis: number = 0, yAxis: number = 0) => {
  window.scrollTo(xAxis, yAxis);
};

export const getValuesAsFields = (
  formikValues: any,
  fieldSections: any[] = []
) => {
  if (!fieldSections || !Array.isArray(fieldSections) || !formikValues) {
    return [];
  }
  const fieldsValue = fieldSections.map((fieldArray: []) => {
    const values: any = {};
    fieldArray.forEach(
      (field) => (values[field['name']] = formikValues[field['name']])
    );
    return values;
  });
  return fieldsValue;
};

export const storeInSession = (dataObj: any) => {
  const Objkeys = Object.keys(dataObj);
  Objkeys.forEach((dataKey: any) => {
    if (dataObj[dataKey]) sessionStorage.setItem(dataKey, dataObj[dataKey]);
  });
};

export const getAuthUserData = (reqData?: string[]) => {
  const requiredDataKeys = reqData
    ? reqData
    : ['userDetails', 'iat', 'exp'];
  const resObj: any = {};
  requiredDataKeys.forEach((reqKey: string) => {
    const valueWithKey = sessionStorage.getItem(reqKey);
    if (valueWithKey)
      resObj[reqKey] =
        reqKey === 'userDetails' ? safelyParseJSON(valueWithKey) : valueWithKey;
  });
  return resObj;
};

export const processAuthResponse = (
  loginResponse: any
): {
  userDetails?: {
    email: string;
    UserName: string;
    AzureObjectID: string;
  };
  iat?: number;
  exp?: number;
} => {
  const {
    data: { email, UserName, AzureObjectID, exp, iat },
  } = loginResponse;
  if (email && UserName && exp && iat) {
    const responseObj = {
      userDetails: { email, UserName, AzureObjectID },
      iat,
      exp,
    };
    storeInSession({
      ...responseObj,
      userDetails: JSON.stringify({
        // onpremADusername,
        email,
        UserName,
        AzureObjectID,
      }),
    });
    return responseObj;
  } else {
    return {};
  }
};

export const requestHandler = (req: any) => {
  let correlationId = window.localStorage.getItem('x-correlation-id');
  if (!correlationId) {
    correlationId = uuidv4();
    window.localStorage.setItem('x-correlation-id', correlationId);
  }
  const { userDetails } = getAuthUserData();
  // const onpremADusername = userDetails?.onpremADusername;
  const username = userDetails?.UserName;
  const email = userDetails?.email;
  const headerObj = { ...req.headers };
  if (req.url.includes(BASE_URL) && !req.url.includes(AUTH_SERVICE_BASE_URL)) {
    return {
      ...req,
      headers: {
        ...headerObj,
        'x-fullname': username,
        // 'X-Username': onpremADusername,
        'x-mail-id': email,
        'x-correlation-id': correlationId,
      },
    };
  } else {
    return {
      ...req
    }
  }
};

export const responseHandler = (res: any) => {
  const responseCorrelation = res.headers['x-correlation-id'];
  let correlationId = window.localStorage.getItem('x-correlation-id');
  if (responseCorrelation && responseCorrelation !== correlationId) {
    window.localStorage.setItem('x-correlation-id', responseCorrelation);
  }
  return res;
};

export const handleError = (error: any) => {
  const parsedError = JSON.parse(JSON.stringify(error));
  if (String(parsedError.message).includes('401')) {
    const url = parsedError?.config?.url || '';
    if (!url.includes(AUTH_SERVICE_BASE_URL)) {
      TriggerToaster('warning', 'Unauthorized, Please login again')
      setTimeout(() => {
        sessionStorage.clear();
        sessionStorage.setItem('prevLogin', 'true');
        window.location.href = API_URLS.doSignout;
      }, 3000)
    }
  }
  return parsedError;
};

export const removeEmpty = (obj: any) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v));
};

export const replaceEmptyKeys = (obj: any) => {
  let r = {};
  Object.entries(obj).map(([key, val]) => {
    var o = { [key]: val || '%' };
    r = { ...r, ...o };
    return o;
  });
  return r;
};
