import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Avatar from "@material-ui/core/Avatar";
import { Box, IconButton, Popover, Typography } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import LogoImage from "../../../resources/images/icons/PRMLogo.jpg";
import SearchFileImage from "../../../resources/images/icons/requestCenterNew.jpg";
import Logout from "../../../resources/images/icons/logout.png";
import MenuIcon from "../../../resources/images/icons/menu.svg";
import { useHandleAuthResponse } from "hooks/authHandler";
import { doSignout } from "core/apiActions/auth";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  avatarWrapper: {
    display: "flex",
  },
  iconWrapper: {
    padding: "0px",
    "&:hover": {
      backgroundColor: "#FFF",
    },
  },
  grow: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: "#ffffff",
    boxShadow:
      "0px 2px 2px -1px rgb(0 0 0 / 9%), 0px 1px 4px 0px rgb(0 0 0 / 2%), 0px 1px 5px 0px rgb(0 0 0 / 14%)",
    // "& header": {

    // },
  },
  profileNameWrapper: {
    display: "flex",
    margin: theme.spacing(0, 1),
    "& .MuiAvatar-root ": {
      [theme.breakpoints.down("lg")]: {
        width: "32px",
        height: "32px",
      },
    },
  },
  welcome: {
    color: "#707d8c",
    fontSize: "0.75rem",
  },
  profileName: {
    color: "black",
    fontWeight: 600,
    [theme.breakpoints.down("lg")]: {
      fontSize: "0.875rem",
    },
  },
  logoImage: {
    cursor: "pointer",
    width: "auto",
    height: "61px",
    padding: theme.spacing(1),
    margin: "0 auto 0 18px",
    objectFit: "contain",
    [theme.breakpoints.down("lg")]: {
      width: "auto",
      height: "37px",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  profileImage: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    background: "#eaedf6",
  },
  title: {
    flexGrow: 1,
  },
  menuIconImage: {
    cursor: " pointer",
    width: "24px",
    height: "18px",
    position: "absolute",
    left: "11px",
  },
  toolbar: {
    minHeight: "52px",
    position: "relative",
  },
  profileNameText: {
    color: "#3277a5",
    fontWeight: 500,
  },
  logoutContent: {
    cursor: "pointer",
    width: "91px",
    height: "31px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .MuiTypography-body1": {
      color: "#000000",
      paddingLeft: "5px",
    },
    boxShadow: "0 0 9px 0 rgba(0, 0, 0, 0.25)",
  },
  logOutStyles: {
    "& .MuiPopover-paper": {
      "& :before": {
        content: '""',
        position: " absolute",
        top: "-20px",
        right: "5px",
        borderBottom: "10px solid black",
        borderRight: "10px solid transparent",
        borderLeft: "10px solid transparent",
        borderTop: "10px solid transparent",
        zIndex: 10,
      },

      [theme.breakpoints.up("xl")]: {
        position: "absolute",
        top: "81px !important",
        left: "95% !important",
      },
      [theme.breakpoints.between(1657, 1920)]: {
        position: "absolute",
        top: "55px !important",
        left: "94% !important",
      },
      [theme.breakpoints.between(1502, 1657)]: {
        position: "absolute",
        top: "55px !important",
        left: "93% !important",
      },
    },
  },
}));

interface ViewSidebar {
  viewSidebarOnclick: Function;
  headerLogo: {
    title?: string;
  };
}

const Header = (props: ViewSidebar): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const [, userDetails] = useHandleAuthResponse();

  const handleClick = () => {
    history.push("/");
  };

  const userName = userDetails ? userDetails?.UserName : "User Name";
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickOpenPopOver = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopOver = () => {
    setAnchorEl(null);
  };

  const initiateHandleLogout = () => {
    handleClosePopOver();
    doSignout();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <AppBar className={classes.appBar} position="fixed">
      <Toolbar className={classes.toolbar}>
        <img
          src={MenuIcon}
          alt="menu"
          onClick={() => props.viewSidebarOnclick()}
          className={classes.menuIconImage}
        />
        {props.headerLogo.title === "SearchFile" ? (
          <img
            onClick={handleClick}
            src={SearchFileImage}
            className={classes.logoImage}
            alt="recmanSearchFilelogo"
          />
        ) : (
          <img
            onClick={handleClick}
            src={LogoImage}
            className={classes.logoImage}
            alt="recamanWarehouselogo"
          />
        )}

        <div className={classes.grow} />
        <div className={classes.profileName}>
          <Typography className={classes.welcome} align="left">
            Welcome
          </Typography>
          <Typography
            className={classes.profileName}
            align="left"
            variant="h6"
            component="h6"
          >
            {userName}
          </Typography>
        </div>
        <div className={classes.profileNameWrapper}>
          <Avatar
            alt="Remy Sharp"
            color="primary"
            className={classes.profileImage}
          >
            <Typography className={classes.profileNameText}>
              {userName[0]}
            </Typography>
          </Avatar>
          <IconButton
            disableFocusRipple
            className={classes.iconWrapper}
            aria-describedby={id}
            onClick={handleClickOpenPopOver}
          >
            <ArrowDropDownIcon color="primary" fontSize="large" />
          </IconButton>
          <div>
            <Box></Box>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClosePopOver}
              anchorReference="anchorPosition"
              anchorPosition={{ top: 55, left: 1400 }}
              className={classes.logOutStyles}
            >
              <Box
                className={classes.logoutContent}
                onClick={initiateHandleLogout}
              >
                <img src={Logout} alt="logout" />
                <Typography> Log Out</Typography>
              </Box>
            </Popover>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
