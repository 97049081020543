import { createSlice } from '@reduxjs/toolkit';
import { initialStateType } from './auth.interface';
import { setAuthenticating, storeAuthUser, updateUsersAccessTabs } from './auth.caseReducer';

const authHandlerSlice = createSlice({
    name: 'Auth_Data',
    initialState: {
        userDetails: {
            data: null,
            loading: false,
        },
        isAuthenticated: false,
        isAuthenticating: false,
        userAccessTabs: [],
    } as initialStateType,
    reducers: {
        updateUsersAccessTabs,
        setAuthenticating,
        storeAuthUser,
    },
})

export { authHandlerSlice };
export default authHandlerSlice.reducer;