/* eslint-disable import/no-anonymous-default-export */
export default {
  fontFamily: [
    '"Roboto"',
    'Open Sans',
    '-apple-system',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ],
  h1: {
    fontFamily: '"Roboto", sans-serif',
  },
  h2: {
    fontFamily: '"Roboto", sans-serif',
  },
  h3: {
    fontFamily: '"Roboto", sans-serif',
  },
  h4: {
    fontFamily: '"Roboto", sans-serif',
    fontWeight: 500,
  },
  h5: {
    fontFamily: '"Roboto", sans-serif',
    fontWeight: 500,
  },
  h6: {
    fontFamily: '"Roboto", sans-serif',
  },
  subtitle1: {
    fontFamily: '"Roboto", sans-serif',
  },
  subtitle2: {
    fontFamily: '"Roboto", sans-serif',
  },
};
