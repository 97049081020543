import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { initialStateType } from "./auth.interface";

export const setAuthenticating: CaseReducer<
    initialStateType,
    PayloadAction<any>
> = (state, action) => {
    state.isAuthenticating = action.payload;
};

export const storeAuthUser: CaseReducer<
    initialStateType,
    PayloadAction<any>
> = (state, action) => {
    state.isAuthenticating = false;
    state.userDetails.data = action.payload.data;
    state.isAuthenticated = !!action.payload.data;
};

export const updateUsersAccessTabs: CaseReducer<
    initialStateType,
    PayloadAction<any>
> = (state, action) => {
    const tabsNames = ['Agency Images'];
    try {
        const { parsedStaffNetUsers, userData: { data: { AzureObjectID } } } = action.payload;
        if(parsedStaffNetUsers.includes(AzureObjectID)) {
            tabsNames.push('StaffNet');
        }
    } catch(err) {
        console.log("On Error",'Unable to set staffnet user permission')
    }
    state.userAccessTabs = tabsNames;
};