import React from 'react';
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom';
import Routes, {  PrintView, SSOLandingPage, UnauthorizedUser } from './routes';
import RouteAuthentication from './routerHelpers';

const AppRoutes = (): JSX.Element => {
  return (
    <Router>
      <Switch>
        {Routes.map((prop, index) => (
          <RouteAuthentication {...prop} key={`appRoute-${index}`} />
        ))}
        <Route 
        exact={true}
        path='/unauthorized'
        component={UnauthorizedUser}
        />
        <Route 
        exact={true}
        path='/'
        component={SSOLandingPage}
        />
        <Route path='/printView' component={PrintView} />
        <Redirect to={'/agency-imaging'} />
      </Switch>
    </Router>
  );
};

export default AppRoutes;
