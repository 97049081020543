const BASE_URL = process.env.REACT_APP_BASE_URL;
const ALB_BASE_URL = process.env.REACT_APP_ALB_BASE_URL;
const MASTER_BASE_URL = `${BASE_URL}/commonservice/api`;
const AUTH_SERVICE_BASE_URL = `${BASE_URL}/recordsIdassService`;

const GENERAL_IMAGING_BASE_URL = `${BASE_URL}/generalimaging/api`;
const STAFFNET_BASE_URL = `${BASE_URL}/staffnet/api`;
const IMAGE_PROCESSOR_BASE_URL = `${BASE_URL}/imageprocessor/api`;
const IMAGE_PROCESSOR_ALB_BASE_URL = `${ALB_BASE_URL}/imageprocessor/alb/api`;
const TIFF_TO_PDF_SERVICE_BASE_URL = `${ALB_BASE_URL}/tifftopdfservice/alb/api`;
const AGENCY_IMAGING_BASE_URL = `${BASE_URL}/agencyimaging/api`;
const AGENCY_IMAGING_ALB_BASE_URL = `${ALB_BASE_URL}/agencyimaging/alb/api`;

class APIUrlconstants {
  static API_URLS = {
    masterStates: `${MASTER_BASE_URL}/master/states`,
    b2eSignIn: `${AUTH_SERVICE_BASE_URL}/signin`,
    getAuthUser: `${AUTH_SERVICE_BASE_URL}/user`,
    doTokenRefresh: `${AUTH_SERVICE_BASE_URL}/refreshtoken/b2c`,
    doSignout: `${AUTH_SERVICE_BASE_URL}/signout`,
    getProvidence: `${STAFFNET_BASE_URL}/providence`,
    getNorfolk: `${STAFFNET_BASE_URL}/norfolk`,
    getBackCases: `${STAFFNET_BASE_URL}/backcases`,
    getPropertyIndex: `${STAFFNET_BASE_URL}/propertyindex`,
    getProbateCards: `${STAFFNET_BASE_URL}/probatecards`,
    masterCountries: `${GENERAL_IMAGING_BASE_URL}/counties`,
    getImageInListPenn: `${IMAGE_PROCESSOR_BASE_URL}/recordsimageslist`,
    getAgencyImaging: `${AGENCY_IMAGING_BASE_URL}/search`,
    getImageInList: `${IMAGE_PROCESSOR_ALB_BASE_URL}/imageslist`,
    getStaffnetDCTiffUrl: `${STAFFNET_BASE_URL}/dcplantfilesrc/tifsignedurl`,
    getStaffnetRITiffUrl: `${STAFFNET_BASE_URL}/rifilesrc/tifsignedurl`,
    covertTiffToPdf: `${TIFF_TO_PDF_SERVICE_BASE_URL}/convert/pdf`,
    getImageUrlData: `${IMAGE_PROCESSOR_BASE_URL}/imagedata`,
    getAgentCodeJson: `${AGENCY_IMAGING_BASE_URL}/agentnetcodes/json`,
    agencyImagingDirectTiff: `${AGENCY_IMAGING_ALB_BASE_URL}/viewsignedurl`,
  };
}
export { AUTH_SERVICE_BASE_URL };
export default APIUrlconstants;
