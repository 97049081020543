import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { initialStateType, loadingPayload } from './general.interface';

export const getScanImages: CaseReducer<
  initialStateType,
  PayloadAction<any>
> = (state, action) => {
  state.imagesOnThumnail.data = action.payload.data;
  state.imagesOnThumnail.loading = false;
};

export const storeUserDetails: CaseReducer<
  initialStateType,
  PayloadAction<any>
> = (state, action) => {
  state.userDetails.data = action.payload.data;
};

export const getNextScanImages: CaseReducer<
  initialStateType,
  PayloadAction<any>
> = (state, action) => {
  state.imagesOnThumnailNext.data = action.payload.data;
  state.imagesOnThumnailNext.loading = false;
};

export const handleLoaders: CaseReducer<
  initialStateType,
  PayloadAction<loadingPayload>
> = (state, action) => {
    const { loaderApplying } = action.payload;
    state[loaderApplying].loading = action.payload.loading;
};

export const updateSidebar: CaseReducer<
  initialStateType,
  PayloadAction<boolean>
> = (state, action) => {
  state.sidebar.open = action.payload;
};

export const convertingPDF: CaseReducer<
initialStateType,
PayloadAction<boolean>
> = (state, action) => {
  state.convertingPdf.data = action.payload;
  state.convertingPdf.loading = false;
};