import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { intialStateType, loadingPayload } from './agencyImaging.interface';

export const handleLoaders: CaseReducer<
  intialStateType,
  PayloadAction<loadingPayload>
> = (state, action) => {
  const { loaderApplying } = action.payload;
  state[loaderApplying].loading = action.payload.loading;
};

export const getAgencyImaging: CaseReducer<intialStateType, PayloadAction<any>> = (
  state,
  action
) => {
  state.agencyImaging.data = action.payload.data;
  state.agencyImaging.loading = false;
};
