/* eslint-disable import/no-anonymous-default-export */
const backgroundColor = "#242e4a";
const dangerColor = '#ff0000';
const primaryColor = '#3277a5';
const secondaryColor = '#23343F';
const lightColor = '#F7FAFA';

export default {
  primary: {
    main: primaryColor,
    light: lightColor,
  },
  secondary: {
    main: secondaryColor,
    light: dangerColor,
  },
  error: {
    main: dangerColor,
  },
  custom: {
    navList: {
      backgroundColor,
    }
  }
};
