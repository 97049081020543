import { combineReducers } from '@reduxjs/toolkit';
import authHandlerReducer from './auth/index';
import staffNetReducer from './staffnet';
import generalDataReducer from './general';
import masterDataReducer from './master';
import agencyImagingReducer from './agencyImaging';

const rootReducer = combineReducers({
  Auth_Data: authHandlerReducer,
  staffNetState: staffNetReducer,
  General_Data: generalDataReducer,
  MasterData: masterDataReducer,
  RF_agencyImaging: agencyImagingReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
