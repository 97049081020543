/* eslint-disable import/no-anonymous-default-export */
import MuiButton from './MuiButton';
import MuiTableCell from './MuiTableCell';
import MuiTableHead from './MuiTableHead';
import MuiTableRow from './MuiTableRow';
import MuiTypography from './MuiTypography';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiInputLabel from './MuiInputLabel';
import MuiMenuItem from './MuiMenuItem';
import MuiTablePagination from './MuiTablePagination';
import MuiList from "./MuiList";

export default {
  MuiButton,
  MuiOutlinedInput,
  MuiInputLabel,
  MuiMenuItem,
  MuiTablePagination,
  MuiTableCell,
  MuiTableHead,
  MuiTableRow,
  MuiTypography,
  MuiList,
};
