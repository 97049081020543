import { createSlice } from '@reduxjs/toolkit';
import { intialStateType } from './staffnet.interface';
import { handleLoaders, getStaffnetRecords } from './staffnet.caseReducers';

const staffnetSlice = createSlice({
  name: 'staffNetState',
  initialState: {
    records: {
      data: [],
      error: '',
      loading: false,
      cols: [],
    },
  } as intialStateType,
  reducers: {
    handleLoaders,
    getStaffnetRecords,
  },
});

export { staffnetSlice };
export default staffnetSlice.reducer;
