import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { intialStateType, loadingPayload } from './staffnet.interface';

// providencePlant norFolkPlant backCases propertyIndex probateCards
export const getStaffnetRecords: CaseReducer<
  intialStateType,
  PayloadAction<loadingPayload>
> = (state, action) => {
  state.records.data = action.payload.data;
  state.records.cols = action.payload.cols;
  state.records.loading = false;
};

export const handleLoaders: CaseReducer<
  intialStateType,
  PayloadAction<loadingPayload>
> = (state, action) => {
  const { loaderApplying } = action.payload;
  state[loaderApplying].loading = action.payload.loading;
};
