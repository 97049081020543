import React, { ReactElement, useState, useEffect } from "react";
// import Typography from '@material-ui/core/Typography';
import clsx from "clsx";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../header";
import NavSideBar from "../navSidebar";

interface Props {
  children: React.ReactNode;
  layoutSettings: {
    title?: string;
    topbar?: boolean;
  };
}

const useStyles = makeStyles((theme) => ({
  containerWrapper: {
    top: "78px",
    position: "relative",
    display: "flex",
    backgroundColor: "#f7fafa",
    [theme.breakpoints.down("lg")]: {
      top: "52px",
    },
  },
  childWrapper: {
    position: "relative",
  },
  mediaScreen: {
    [theme.breakpoints.up("lg")]: {
      maxWidth: "1920px",
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: "100%",
    },
  },
  removeHorizontalScroll: {
    overflowX: "hidden",
    paddingBottom: "16px",
  },
  sidebar: {
    minWidth: 240,

    [theme.breakpoints.down("lg")]: {
      minWidth: 230,
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: 0,
    },
  },
  sidebarClose: {
    [theme.breakpoints.down("lg")]: {
      minWidth: 45,
    },
    [theme.breakpoints.only("xl")]: {
      minWidth: 45,
    },
  },
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function LayoutWrapper({ children, layoutSettings = {} }: Props): ReactElement {
  const classes = useStyles();
  const [sidebarOpen, setsidebarOpen] = useState(true);
  function handleViewOnclick() {
    setsidebarOpen(!sidebarOpen);
  }
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 1024) {
        setsidebarOpen(false);
      } else {
        setsidebarOpen(true);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <Header
        viewSidebarOnclick={handleViewOnclick}
        headerLogo={layoutSettings}
      />
      <div className={classes.containerWrapper}>
        <div
          className={clsx(sidebarOpen ? classes.sidebar : classes.sidebarClose)}
        >
          <NavSideBar navList={layoutSettings.title} isOpen={sidebarOpen} />
        </div>
        <Container
          fixed
          className={clsx(classes.removeHorizontalScroll, classes.mediaScreen)}
        >
          <div className={classes.childWrapper}>{children}</div>
        </Container>
      </div>
    </>
  );
}

export default LayoutWrapper;
