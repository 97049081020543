/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import {
  errorPayload,
  intialStateType,
  loadingPayload,
} from './master.interface';

export const getMasterStates: CaseReducer<
  intialStateType,
  PayloadAction<any>
> = (state, action) => {
  state.masterStates.data = action.payload.data;
  state.masterStates.loading = false;
};

export const getMasterCountries: CaseReducer<
  intialStateType,
  PayloadAction<any>
> = (state, action) => {
  state.masterCountries.data = action.payload.data;
  state.masterCountries.loading = false;
};

export const getAgentCodes: CaseReducer<intialStateType, PayloadAction<any>> = (
  state,
  action
) => {
  state.agentCodes.data = action.payload.data;
  state.agentCodes.loading = false;
};

export const handleLoaders: CaseReducer<
  intialStateType,
  PayloadAction<loadingPayload>
> = (state, action) => {
  const { loaderApplying } = action.payload;
  state[loaderApplying].loading = action.payload.loading;
};
